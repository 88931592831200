import {
  CARRIER_PROFILE_PUBLIC_SERVICE_AUDIENCE,
  CARRIER_PROFILE_PUBLIC_SERVICE_URL,
} from '@/common/config'
import { getAuth0Header } from '@/services/tokenManager'
import {
  Carrier,
  CarrierApi,
  Configuration,
  Contact,
  ContactsApi,
  UserApi,
  User,
  CarrierVettingStatus,
} from '@sennder/carrier-profile-public-axios-client'
import {
  IUserCompany,
  IUserContact,
} from '@sennder/senn-node-microfrontend-interfaces'
import carrierUserService from './carrierUserService'
import { ICUSUser } from '@/types/cus'
import { iso3661Countries } from '../helpers/ISO3166Countries'

class CarrierProfilePublicService {
  private async getApiConfig(): Promise<Configuration> {
    const accessToken = await this.getCPPSToken()
    const options = {
      headers: {
        Authorization: accessToken,
      },
    }
    return new Configuration({
      basePath: CARRIER_PROFILE_PUBLIC_SERVICE_URL,
      baseOptions: options,
    })
  }

  async carrierApiInstance(): Promise<CarrierApi> {
    const apiConfig = await this.getApiConfig()
    return new CarrierApi(apiConfig)
  }

  async contactsApiInstance(): Promise<ContactsApi> {
    const apiConfig = await this.getApiConfig()
    return new ContactsApi(apiConfig)
  }

  async userApiInstance(): Promise<UserApi> {
    const apiConfig = await this.getApiConfig()
    return new UserApi(apiConfig)
  }

  async getCPPSToken(): Promise<string> {
    const authTokenHeader = await getAuth0Header({
      audience: CARRIER_PROFILE_PUBLIC_SERVICE_AUDIENCE,
    })
    if (!authTokenHeader) {
      throw new Error('[CPPS]: Cant build a request. Token is empty')
    }

    return authTokenHeader
  }

  async getCarrier(carrierId: string): Promise<Carrier> {
    try {
      const api = await this.carrierApiInstance()
      const response = await api.readCarrierV1CarriersCarrierIdGet(carrierId)
      if (response.status !== 200) {
        throw new Error()
      }
      return response.data
    } catch (error: any) {
      throw new Error(`Error getting carrier data from CPPS ${error.stack}`)
    }
  }

  async getPrimaryContact(carrierId: string): Promise<Contact | null> {
    try {
      const api = await this.contactsApiInstance()
      const response =
        await api.listCarrierContactsV1CarriersCarrierIdContactsGet(
          carrierId,
          undefined,
          true
        )

      if (response.data.results?.length > 0) {
        const result = response.data.results[0]
        if (result) {
          return response.data.results[0]
        }
      }
    } catch (error: any) {
      const errorMessage = `Error fetching primary contact for carrierId ${carrierId} from CPPS: ${JSON.stringify(
        error.response?.data ?? error.message
      )}`
      throw new Error(errorMessage)
    }
    return null
  }

  public async getContactDetails(): Promise<IUserContact> {
    let user: ICUSUser

    try {
      user = await carrierUserService.whoami()

      if (!user.carrier_ids?.[0]) {
        throw new Error('User is not associated with a carrier')
      }
    } catch (error: any) {
      throw new Error('Error fetching CUS user data')
    }

    const carrierId = String(user.carrier_ids[0])

    const carrier = await this.getCarrier(carrierId)
    const mothershipCarrierId = String(carrier.mothership_id) ?? ''

    const contact = await this.getPrimaryContact(carrierId)

    const contactDetails: IUserContact = {
      carrierId: mothershipCarrierId,
      email: contact?.email ?? '',
      firstName: contact?.first_name ?? '',
      lastName: contact?.last_name ?? '',
      fullName: `${contact?.first_name ?? ''} ${contact?.last_name ?? ''}`,
      jobTitle: contact?.role ?? '',
      phone: contact?.phone ?? '',
    }

    return contactDetails
  }

  async getUser(userId: string): Promise<User> {
    try {
      const api = await this.userApiInstance()
      const { data } = await api.readUserV1UsersUserIdGet(userId)
      return data
    } catch (error: any) {
      const errorMessage = `Error getting user data from CPPS ${
        error.response?.data || error.message
      }`

      throw new Error(errorMessage)
    }
  }

  async loadCarrierManagerEmail(
    carrier_manager_id: string | undefined
  ): Promise<string> {
    try {
      if (!carrier_manager_id) {
        return ''
      }
      const carrierManager = await this.getUser(carrier_manager_id)
      return carrierManager.email
    } catch (error: any) {
      const errorMessage = `[CPPS Service]: Unable to fetch user data, error: ${error.stack}`

      throw new Error(errorMessage)
    }
  }

  getCompanyCarrierTier(carrier: Carrier): number {
    const { vetting_status, is_denylisted } = carrier

    if (vetting_status === CarrierVettingStatus.Completed && !is_denylisted) {
      return 2
    }
    return 0
  }

  public async getCompanyDetails(): Promise<readonly [IUserCompany, Carrier]> {
    let user: ICUSUser

    try {
      user = await carrierUserService.whoami()

      if (!user.carrier_ids?.[0]) {
        throw new Error('User is not associated with a carrier')
      }
    } catch (error: any) {
      throw new Error('Error fetching CUS user data')
    }

    const carrierId = user.carrier_ids[0]

    const carrier = await this.getCarrier(carrierId)

    const carrier_manager_email = await this.loadCarrierManagerEmail(
      carrier.carrier_manager
    )

    const addressParts = [
      carrier.address?.street,
      `${carrier.address?.postal_code} ${carrier.address?.city}`,
      carrier.address?.country ? iso3661Countries[carrier.address.country] : '',
    ].filter((part) => part)

    const address = addressParts.join(', ')

    const companyCarrierTier = this.getCompanyCarrierTier(carrier)

    const companyDetails: IUserCompany = {
      accountOwnerEmail: carrier_manager_email,
      address,
      carrierId: carrier.mothership_id ?? '',
      cpsCarrierId: carrier.id,
      carrierType: carrier.type,
      companyCarrierTier: companyCarrierTier,
      companyIsInVettingProcess:
        carrier.vetting_status === CarrierVettingStatus.InProgress,
      contact: `${user.first_name} ${user.last_name}`,
      isCarrier: true,
      name: carrier.company_name,
      vatId: `${carrier.vat.country_code}${carrier.vat.number}`,
      germanVatId: '',
    }

    return [companyDetails, carrier] as const
  }
}

export default new CarrierProfilePublicService()
