import { IModulesConfig } from '@sennder/senn-node-microfrontend-interfaces'

export const moduleConfiguration: IModulesConfig[] = [
  {
    name: 'Marketplace',
    component: 'marketplace-mf-component',
    devPort: '9104',
    route: '/marketplace',
    layout: 'AppLayoutMain',
    middlewares: ['isAuthenticated'],
    analyticsContext: {
      module: 'marketplace',
      submodule: '',
    },
    logContext: {
      module: 'marketplace-mf-component',
      codeOwners: 'pod-discovery',
    },
  },
  {
    name: 'Assignments',
    component: 'assignments-mf-component',
    devPort: '9108',
    route: '/assignments',
    layout: 'AppLayoutMain',
    middlewares: ['isAuthenticated'],
    analyticsContext: {
      module: 'assignments',
      submodule: '',
    },
    logContext: {
      module: 'assignments-mf-component',
      codeOwners: 'matching',
    },
  },
  {
    name: 'OrderDetails',
    component: 'marketplace-mf-component',
    devPort: '9104',
    route: '/order-details/:idForStaff',
    layout: 'AppLayoutMain',
    middlewares: ['isAuthenticated'],
    analyticsContext: {
      module: 'order-details',
      submodule: '',
    },
    logContext: {
      module: 'marketplace-mf-component',
      codeOwners: 'pod-discovery',
    },
  },
  {
    name: 'TenderDetails',
    component: 'marketplace-mf-component',
    devPort: '9104',
    route: '/tender-details/:id',
    layout: 'AppLayoutMain',
    middlewares: ['isAuthenticated'],
    analyticsContext: {
      module: 'tender-details',
      submodule: '',
    },
    logContext: {
      module: 'marketplace-mf-component',
      codeOwners: 'pod-discovery',
    },
  },
]
