import { getStateUser } from '@/store'
import {
  identifyUserInAnalytics,
  initAnalytics,
  resetAnalyticsSession,
  stopAnalytics,
} from './analytics'
import { identifyUserInLogger, initLogger, stopLogger } from './logger'
import { logger } from './logger/loggers'
import { identifyUserInMonitor, initMonitor, stopMonitor } from './monitor'

let state: 'stopped' | 'started' = 'stopped'

export function startTracking() {
  if (state === 'started') {
    return
  }
  initLogger()
  initMonitor()
  initAnalytics()
  state = 'started'

  log('tracking is started')
}

export function identifyUserInTracking(onLogin = false) {
  if (state === 'stopped') {
    return
  }

  const user = getStateUser()

  identifyUserInAnalytics(onLogin)
  identifyUserInLogger(String(user.id), user.email)
  identifyUserInMonitor(String(user.id), user.email)

  log('user is identified')
}

export function anonymizeTracking() {
  if (state === 'stopped') {
    return
  }
  resetAnalyticsSession()

  log('user is anonymized')
}

export function stopTracking() {
  stopAnalytics()
  stopMonitor()
  stopLogger()
  state = 'stopped'

  log('tracking is stopped')
}

function log(message: string) {
  const prefix = '[orcas-mobile-shell - tracking]'
  logger.info(`${prefix} ${message}`, {})
  console.log(`${prefix} ${message}`)
}
