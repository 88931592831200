import { parseTenantUrl } from '@/plugins/tenants'
import { Tenant } from '@sennder/senn-node-microfrontend-interfaces'

// Auth0 config
const auth0ClientIds = {
  [Tenant.SENNDER]: process.env.VUE_APP_AUTH0_CLIENT_ID,
  [Tenant.POSTE]: process.env.VUE_APP_AUTH0_CLIENT_ID_ITALIA,
  [Tenant.DEMO]: process.env.VUE_APP_AUTH0_CLIENT_ID_DEMO,
}

// Mothership URL config
const mothershipUrls = {
  [Tenant.SENNDER]: process.env.VUE_APP_MOTHERSHIP_URL,
  [Tenant.POSTE]: process.env.VUE_APP_MOTHERSHIP_URL_ITALIA,
  [Tenant.DEMO]: process.env.VUE_APP_MOTHERSHIP_URL_DEMO,
}

const activeTenant = parseTenantUrl()

export const ENV = process.env.VUE_APP_ENV as 'dev' | 'prod' | 'uat'
export const APP_VERSION = process.env.VUE_APP_VERSION
export const CARRIER_PROFILE_URL = process.env.VUE_APP_CARRIER_PROFILE_URL
export const MOTHERSHIP_URL =
  mothershipUrls[activeTenant as keyof typeof mothershipUrls]
export const LD_KEY = process.env.VUE_APP_LD_KEY
export const LD_USER_STATIC_KEY = process.env.VUE_APP_LD_USER_STATIC_KEY
export const DATADOG_APP_ID = process.env.VUE_APP_DATADOG_APP_ID
export const DATADOG_CLIENT_TOKEN = process.env.VUE_APP_DATADOG_CLIENT_TOKEN
export const AMPLITUDE_API_KEY = process.env.VUE_APP_AMPLITUDE_API_KEY
export const GTM_CONTAINER_ID = process.env.VUE_APP_GTM_CONTAINER_ID
export const TRANSLATIONS_URL = process.env.VUE_APP_TRANSLATIONS_URL
export const LOCAL_MICROFRONTENDS = process.env.VUE_APP_LOCAL_MICROFRONTENDS
export const CARRIER_USER_SERVICE_URL =
  process.env.VUE_APP_CARRIER_USER_SERVICE_URL
export const CARRIER_USER_SERVICE_AUDIENCE =
  process.env.VUE_APP_CARRIER_USER_SERVICE_AUDIENCE
export const CARRIER_PROFILE_PUBLIC_SERVICE_URL =
  process.env.VUE_APP_CARRIER_PROFILE_PUBLIC_SERVICE_URL
export const CARRIER_PROFILE_PUBLIC_SERVICE_AUDIENCE =
  process.env.VUE_APP_CARRIER_PROFILE_PUBLIC_SERVICE_AUDIENCE
export const PERMISSIONS_SERVICE_URL =
  process.env.VUE_APP_PERMISSIONS_SERVICE_URL

export const IS_LOCALHOST = window.location.hostname === 'localhost'
export const AUTH0_DOMAIN = process.env.VUE_APP_AUTH0_DOMAIN
export const AUTH0_MOTHERSHIP_AUDIENCE =
  process.env.VUE_APP_AUTH0_MOTHERSHIP_AUDIENCE
export const AUTH0_CLIENT_ID =
  auth0ClientIds[activeTenant as keyof typeof auth0ClientIds]
export const AUTH0_API_AUDIENCE = process.env.VUE_APP_AUTH0_API_AUDIENCE

export const POEDITOR_API_TOKEN = 'c130a9b9ed1b9b5c5fba0392681d3c53'
export const POEDITOR_PROJECT_ID = '381747'

export const AUTH0_USE_TOKEN_POPUP = false

export const isLocalEnv = (): boolean => {
  return ENV === 'dev'
}

export function isUatEnv() {
  return ENV === 'uat'
}

export function isProductionEnv() {
  return ENV === 'prod'
}
